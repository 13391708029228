<template>
    <div class="loader-container" :class="{'loaded':isLoading, 'd-none': dnone}" @wheel.prevent="disabled_wheel">
      <div class="loader-block">
        <span class="loadingPercent">{{  loaded  }}</span>
        <figure class="loader-brush"><img :src='this.$pathprod+"images/saint-ouen/cercle-img.png"' alt="loader-circle" preload></figure>
        <svg class="gauge-svg" width="200" height="200" viewPort="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <circle r="90" cx="100" cy="100" fill="transparent" stroke="#fff" stroke-dasharray="565.48" stroke-dashoffset="0" class="gauge-path"></circle>
        </svg>
      </div>
    </div>
</template>

<script>

export default {
  name: 'Loader',
  props: {
    msg: String,
  },
 
  data() {
    return{
      dnone: false,
      componentKey:0,
      scroll: null,
      isLoading: false,
      loadingPercent: 0,
      loadTime: 0,
      interval: null,
      speedT: 0.5,
      playVideo: false,  
    }
  },

  created() {
    //Get ressource loaded
      let perfData = window.performance.timing;
      let estimatedTime = Math.abs(perfData.loadEventEnd - perfData.navigationStart);
      this.loadTime = parseInt((estimatedTime / 1000) % 60) * 100;
      this.doProgress();
  },
  
  methods: {
      isPlay(){
        this.playVideo = true
      },

      doProgress() {
          let step = this.loadTime / 100;
          this.interval = setInterval(() => {
            this.loadingPercent++
          }, step);
      },
      playEnteteVideo(enteteVideo){
        var video = document.querySelector(enteteVideo + " .bg-hero")
          if(video){
              video.play()           
              let inter = setInterval(() =>{
                  if(video.paused && video.ended == false ){
                      video.play()
                  }
              }, 500);

              video.addEventListener("ended", function(){
                clearInterval(inter)
              })
          }
      },
      disabled_wheel(e){
          e.stopPropagation();
          return false;
      }
  },

  computed: {
    //Display Percent to html
      loaded() {
        return this.loadingPercent + '%'
      }
  },

  watch: {
    loadingPercent(val) {
      if (val >= 100) {
        clearInterval(this.interval)
         this.isLoading = true;
         
        setTimeout(function(){
          this.dnone = true;
        }.bind(this), 800);

        //play video after load
          if(this.playVideo){
                return false
          }else{
              this.playEnteteVideo("#saintOuen-1")
              this.playEnteteVideo(".section-first")
              this.playEnteteVideo(".tl-ctnLeft")
          }
      }else{
         let strokePercent = window.getComputedStyle(document.querySelector('.gauge-path'),null).getPropertyValue("stroke-dasharray").split('px');
         //Detect if navigator is Safari pour changer la rotation du loader
         let userAgent = navigator.userAgent;

         if(userAgent.match(/chrome|chromium|crios/i)){
            document.querySelector('.gauge-path').style.strokeDashoffset = -(this.loadingPercent*strokePercent[0])/100;
         }else if(userAgent.match(/firefox|fxios/i)){
            document.querySelector('.gauge-path').style.strokeDashoffset = -(this.loadingPercent*strokePercent[0])/100;
         }  else if(userAgent.match(/safari/i)){
            document.querySelector('.gauge-path').style.strokeDashoffset = (this.loadingPercent*strokePercent[0])/100;
         }else if(userAgent.match(/opr\//i)){
            document.querySelector('.gauge-path').style.strokeDashoffset = -(this.loadingPercent*strokePercent[0])/100;
         } else if(userAgent.match(/edg/i)){
            document.querySelector('.gauge-path').style.strokeDashoffset = -(this.loadingPercent*strokePercent[0])/100;
         }else{
            document.querySelector('.gauge-path').style.strokeDashoffset = -(this.loadingPercent*strokePercent[0])/100;
         }
      }
    }
  },
}
</script>